import React, { useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import Layout from '../../components/Layout'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Section from '../../components/Layout/Section'
import { CirclePicker } from 'react-color'
import Icon from '../../images/icons.svg'
import BigImage from '../../images/product-big.png'
import ThumbImage1 from '../../images/product-1.png'
import Arrives from '../../images/arrives.png'
import Warranty from '../../images/warranty.png'
import USA from '../../images/usa.png'
import DocumentBlock from '../../components/Document'
import TableBlock from '../../components/Table'
import SmallVideoBlock from '../../components/SmallVideos'
import Title from '../../components/Layout/Title'
import FeaturedProductItem from '../../components/FeaturedProduct/FeaturedProductItem'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow next"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow prev"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

// markup
const ProductDetails = () => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [count, setCount] = useState(1)
  const [hex, setHex] = useState('#4F73F1')

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    asNavFor: nav2,
    ref: slider1 => setNav1(slider1),
  }

  const thumbSettings = {
    dots: false,
    infinite: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    asNavFor: nav1,
    ref: slider2 => setNav2(slider2),
    slidesToShow: 7,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1544,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  function handlePlus() {
    setCount(count + 1)
  }

  function handleMinus() {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  function handleValueChange(e) {
    e.preventDefault()
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const x = Number(e.target.value)
      setCount(x)
    }
  }

  return (
    <Layout>
      <div className="product-details">
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <Slider {...settings} className="product-image-slider">
                {Array.from({ length: 5 }).map((_, index) => (
                  <div className="product-image-item" key={index}>
                    <img src={BigImage} />
                    <div className="btn-zoom">
                      <svg width="20" height="20">
                        <use xlinkHref={`${Icon}#icon-search`}></use>
                      </svg>
                    </div>
                  </div>
                ))}
              </Slider>
              <Slider {...thumbSettings} className="product-thumb-slider">
                {Array.from({ length: 5 }).map((_, index) => (
                  <div className="product-thumb-item" key={index}>
                    <img src={ThumbImage1} />
                  </div>
                ))}
              </Slider>
            </Col>
            <Col xs={12} lg={6}>
              <div className="product-info">
                <div className="d-flex align-item-start justify-content-between">
                  <p className="subtitle subtitle-sm">Virco</p>
                  <button className="btn-like">
                    <svg viewBox="0 0 512 512" width="28" height="28">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </button>
                </div>
                <div className="info-top">
                  <h1 className="h3">Double Sided Book Station 600H</h1>
                  <p className="text-sm text-secondary">CODE: 14642</p>
                </div>
                <div className="info-group">
                  <div className="price">
                    <h4>$182.00</h4>
                    <p className="old-price">$299.00</p>
                  </div>
                  <p className="text-sm">Excl. GST: $165.45</p>
                </div>
                <div className="info-group">
                  <div className="d-flex align-item-start justify-content-between">
                    <div>
                      <h5>COLOR</h5>
                      <p className="text-sm text-secondary">Cobalt Blue</p>
                    </div>
                    <div>
                      <CirclePicker
                        colors={[
                          '#4F73F1',
                          '#DBDBDB',
                          '#FFD337',
                          '#B4EEA5',
                          '#75DFCC',
                        ]}
                        color={hex}
                        onChange={color => {
                          setHex(color.hex)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="info-group">
                  <h5>SIZE</h5>
                  <div className="size-group">
                    <div className="radio-group">
                      <input
                        type="radio"
                        name="size"
                        id="350mm"
                        value="350mm"
                      />
                      <label htmlFor="350mm">350mm</label>
                    </div>
                    <div className="radio-group">
                      <input
                        type="radio"
                        name="size"
                        id="400mm"
                        value="400mm"
                      />
                      <label htmlFor="400mm">400mm</label>
                    </div>
                    <div className="radio-group">
                      <input
                        type="radio"
                        name="size"
                        id="450mm"
                        value="450mm"
                      />
                      <label htmlFor="450mm">450mm</label>
                    </div>
                  </div>
                </div>
                <div className="info-group">
                  <p className="h5">QUANTITY</p>
                  <div className="quantity-group mt-3 mb-5">
                    <button onClick={handleMinus}>-</button>
                    <input
                      type="number"
                      min="0"
                      value={count}
                      onChange={handleValueChange}
                    />
                    <button onClick={handlePlus}>+</button>
                  </div>
                  <div className="btn-wrap">
                    <a href="#" className="btn btn-primary">
                      Add to Cart
                    </a>
                  </div>
                </div>
                <div className="info-group">
                  <div className="image-group">
                    <img src={Arrives} />
                    <img src={Warranty} />
                    <img src={USA} />
                  </div>
                  <ul>
                    <li>
                      Subtle rocking motion helps calm restless students and
                      fulfills their intrinsic need to move
                    </li>
                    <li>
                      Soft plastic shell seat is ergonomically designed to
                      provide support where it is needed most
                    </li>
                    <li>
                      Healthier sitting leads to increased attention,
                      engagement, and ultimately, student behaviour improvement
                    </li>
                    <li>
                      Students of all sizes will be comfortable in the oversized
                      seat
                    </li>
                    <li>
                      A flexible backrest that bends slightly with natural
                      movements
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Section noSpacingBottom noSpacingTop className="bg-gray">
        <DocumentBlock />
      </Section>
      <Section>
        {/* based on table block */}
        <Container>
          <Title className="text-center">
            <h5>PRODUCT DIMENSIONS</h5>
          </Title>
          <Table className="table-block" responsive>
            <thead>
              <tr>
                <th width="270">Name/ID</th>
                <th>Table Height</th>
                <th>Age Range</th>
                <th>Code</th>
                <th width="240">Virco</th>
                <th>Seat Height</th>
                <th>Seat Width</th>
                <th>Overall Height</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analogy Rocker Chair 350mm</td>
                <td>560mm</td>
                <td>4yrs-8yrs</td>
                <td>50110-Colour</td>
                <td>ANROCK14-BLU65-CHRM</td>
                <td>325</td>
                <td>310</td>
                <td>530</td>
              </tr>
              <tr>
                <td>Analogy Rocker Chair 400mm</td>
                <td>610mm</td>
                <td>6yrs-12yrs</td>
                <td>50111-Colour</td>
                <td>ANROCK16-BLU65-CHRM</td>
                <td>375</td>
                <td>350</td>
                <td>620</td>
              </tr>
              <tr>
                <td>Analogy Rocker Chair 450mm</td>
                <td>670mm</td>
                <td>Adult</td>
                <td>50112-Colour</td>
                <td>ANROCK14-BLU65-CHRM</td>
                <td>440</td>
                <td>400</td>
                <td>750</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Section>
      <Section className="bg-gray">
        <SmallVideoBlock />
      </Section>
      <Section hasDivider>
        {/* based on featured product */}
        <Container>
          <Title className="text-center" title="Similar Products"></Title>
          <Row className="justify-content-center">
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
          </Row>
        </Container>
      </Section>
      <Section>
        {/* based on featured product */}
        <Container>
          <Title className="text-center" title="You May Also Like"></Title>
          <Row className="justify-content-center">
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default ProductDetails
